@import '../../../newStyles/text.less';
@import '../../../newStyles/components.less';
@import '../../../newStyles/variables.less';


.RegisterPage {
  .noContainer();
  display: flex;
  justify-content: center;
  align-items: center;

  .registerDetails {
    .generic-centered-section();
    .constrained-bounds();

    .registerHeader {
      margin-top: 4rem;
      width: 60%;
      max-width: 490px;
      text-align: center;
      @media only screen and (max-width: @xxs) {
        width: 90%;
      }

    }

    Form {
      margin-top: 3rem;
      width: 60%;
      max-width: 480px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: start;
         
      @media only screen and (max-width: @xxs) {
        width: 90%;
      }


      Input {
        line-height: 2.5rem ;
        font-size: @font3;
    
      }

      Checkbox {
        font-size: @font3;
      }

      .errorBox {
        margin-bottom: 2rem;
        .danger {
          color: @danger;
        }
      }

      #registerButton {
        width: 100%;
        height: 45px;
        .button-black-square();
        margin: auto;
      }
    }

    .loginLink {
      margin-top: 24px;
      text-align: center;
    }
  }
    
}

