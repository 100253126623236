@import '../../../../styles/base.less';

.AllTeamsPage {
  
  h3, h4 {
    margin: 0;
    padding: 0;
  } 

  p, .subheader  {
    color: @b2;
  }

  .AllTeamsContent {
    padding: 0 2rem;
  }

  .teamBlock {
    margin: 2rem 0;
    padding: 1rem;
    border: 1.2px solid @gray;
    border-radius: 0.6rem;
    // background-color: @gray;
  }

  .teamBlock:hover {
    background-color: @gray;
    .teamBlockHeader {
      background-color: @white;
    }
  }

  .teamCreateForm {
    input, button {
      border-radius: 0.6rem;
    }
  }

  .teamCreateForm:hover {
    background-color: white;
  }
  
  .teamBlockSection {
    margin: 0.4rem 0;
  }
  
  .teamBlockHeader {
    margin-right: 0.5rem;
    padding: 0.2rem 0.5rem;
    background-color: @gray;
    border-radius: 0.3rem;
  }
  
  .errorMessage {
    margin: 2rem;
  }
}