@import '../../../../styles/base.less';
.CompetitionSpecificTeamPage {
  li {
    list-style-type: none;  
  }
  
  .subheader {
    color: @b2;
  }

  .block {
    margin: 2rem 0;
    background-color: @gray;
    padding: 1rem 1.2rem;
    border-radius: 0.5rem;

    h4 {
      margin: 0;
    }
  }

  .errorMessage {
    margin: 2rem;
  }
  .ant-table-row {
    cursor: pointer;
  }
}