@import '../../../styles/base.less';
@import '../../../newStyles/layout.less';
@import '../../../newStyles/components.less';
@import '../../../newStyles/variables.less';

.NNRankPage {
  .noContainer();
  .generic-section();
  h2 {
    margin-top: 100px;
    color: @black;
  }
  .meta-data {
    font-size: @font2;
    margin-bottom: @margin-base;
  }
  .meta-data-title {
    margin-bottom: 0px;
  }
  .refresh-btn {
    margin-left: 1rem;
  }

}