// This file organizes classNames related to content alignment 

@import './variables.less';

// classNames to align innerContent //
.center-both-ways {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin:auto;
}

.noContainer { 
    margin: 0;
    padding: 0;
    overflow-x: hidden;  // prevents user from scrolling horizontally
    padding-top: 88px;  // shifts page content down to make room for nav bar
}


.center-horizontal {
    display: flex;
    justify-content: center;
}

.even-spaced-row {
    justify-content: space-between;
}

