@import '../../newStyles/variables.less';
.number {
  //border-left: 8px solid;
  border: none;
  display: inline-block;
  width: 150px;
  @media only screen and (max-width: @xs) {
    width: 120px;
  }

  .count {
    display: block;
    font-size: @font4;
    line-height: @font4;
    font-weight: @semi-bold;
    @media only screen and (max-width: @xs) {
      font-size: @font5;
    }
  }

  .description {
    display: block;
    font-size: @font2;
    @media only screen and (max-width: @xs) {
      font-size: @font4;
    }
    font-weight: @regular;
    line-height: 32.25px;
    color: rgb(155, 155, 155);
  }
}