@import '../../styles/base.less';
.AboutPage {
  .noContainer();
  @import '../../styles/impactful.less';
  p {
    font-size: @font2;
  }
  .Card {
    width: auto;
    h3 {
      margin: 0;
      font-size: @font3;
      color: inherit;
    }
    p{
      font-size: @font2;
    }
  }
}
