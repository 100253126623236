@import '../../newStyles/text.less';
@import '../../newStyles/components.less';
@import '../../newStyles/index.less';
@import '../../newStyles/variables.less';


.projectCardHeader {
  display: flex; 
  flex-direction: row;
  align-items: center;
  

  .projectQuarter {
    font-weight: @semi-bold;
    margin-left: 1rem;
  }

}

  .ProjectCard {
    border: none;

    @media screen and (max-width: @md) {
      height: 100% !important/* Adjusted width for smaller screens */
    }

    .title {
      font-weight: @semi-bold;
      margin-top: 1rem;
      color: @b2;
    }

    .projectCardInfo {
      margin: 0;

    }

    .descriptionBox {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 10px;
      margin: 1rem 0 2rem 0;
      display: -webkit-box;

      /* webkit attributes supported in 93% of all browsers*/
      -webkit-line-clamp: 2; /* Limit the number of lines to 2 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      p {
        margin: 0;
        white-space: normal;
        color: @text-gray2;

      }

    }

    .iconsBox {
      display: flex;
      flex-direction: row;
      align-items: center;

      .viewModalButton {
        border: none;
        background: @black;
        box-shadow: 0px 3px 5px 1px rgba(189, 189, 189, 0.5);
        display: flex;
        padding: 1rem;
        align-items: center;
        justify-content: center;
        p {
          color: @white;
        }

      }

      .gitHubButton {
        border: none;
        background: rgb(245, 245, 245);
        display: flex;
        padding: 1rem;
        align-items: center;
        p {
          color: @black;
          @media screen and (max-width: @xs) {
            display: none;
            margin: none;
          }
        }
      }


    
    }


    .tags {
      display: flex;
      text-align: center;
      justify-content:initial;
      flex-grow: 1;
      flex-wrap: wrap;
      overflow-y: hidden;
      margin-bottom: 0.5rem;
      margin-top: 0.5rem;
    }
    .ant-tag {
      margin-right: 3px;
      margin-bottom: 4px;
    }
    .description {
      font-size: @font1;
    }

  }

.ant-modal-content {
  border-radius: 2rem;

  .project-img {
    width: 100%;
    height: 200px;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    border-radius: 1rem 1rem 0 0;
  }
  .title {
    margin-bottom: 1rem;
    font-weight: @semi-bold;
  }
  .ant-tag {
    margin: 3px;
    border-radius: @radius-base3;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: right;
    
    a {
      margin: 0 16px;
    }
  }
  
}
.ant-modal-body {
  .projectModalDescriptionBox {
    margin: 1rem 0;
    background: #F6F6F6;
    border-radius: @radius-base5;
    padding: 1rem;
    .description {
      margin: 1rem;
    }

  }

}