// This file is to organize classNames for generic containers and layouts

@import './text.less';
@import './layout.less';
@import './variables.less';




// provides app theme linear gradient: red -> yellow
.gradient {
    background: linear-gradient(90deg, @mainred, @mainyellow);
}


// Every page has a header so it's better to keep a single source of truth here
.header-section {
    .generic-section();
}   


.footer-section {
    display: flex;
    flex-direction: column;
}


// A generic container to hold new content centered nicely
.generic-centered-section {
    .center-both-ways();
    .generic-section();
}

/* A constrained bounds has a width ratio of 70% and a
 * max width of 1024px so it does not
 * extend all the way to the screen's edges. This way we get 
 * content with enough whitespace on the horizontal axis.
 * Because all pages will be composed of the .noContainer()
 * className, a section with no bounded width is achieved by
 * omitting this class and/or .generic-section
 */
.constrained-bounds {
    width: 70%;
    margin: 0 auto;
    max-width: 1024px;

    @media screen and (max-width: @sm){
        width: 100%;
    }
}


// A wrapper for most sections with content 
.generic-section {
    padding: 3rem @padding-base2;
    margin: 0 auto; /* This will center the container horizontally */
    box-sizing: border-box; /* Include padding and borders within the width */
}


// gradient bar for separating sections
.gradient-bar {
  .gradient();
  padding: 3rem;
}



//  For Square Buttons
.button-black-square {
    border-radius: @radius-base3;
    border-style: none;
    .button-black();
}


/* Used for giving basic styling for nay button*/
.button-black {
    background-color: @b1;
    color: @white;
    border-style: none;

    &:hover {
        color: @mainred;
    }
}

.button-colorful {
    background: linear-gradient(#F66161, #FF8130);
    border-style: none;
    color: @white;

    &:hover {
        color: @text-gray2;
    }
}

.button-red-outline {
    background-color: white;
    border-color: @mainred;
    color: @mainred;
    &:hover {
        border-color: @text-gray2;
        color: @text-gray2;
    }
}

.text-colorful {
    background: linear-gradient(#F66161, #FF8130);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;  /* For Firefox */
  color: transparent;
}
