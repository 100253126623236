@import '../../styles/base.less';
@import '../../newStyles/variables.less';
@import '../../newStyles/components.less';

.Header {
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 88px;
  display: flex;
  flex-direction: column;
  background: @white;

  .navBarWrapper {
    
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 2rem;

  }
  
  .navBarGradientLine {
    .gradient();
    height: 8px;
    z-index: 100;
  }



  .navItem{
    margin: 0 1rem;
    font-size: @font3;
    color: @black;
    font-weight: @semi-bold;
    a {
      color: @black;
    }
    a:hover {
      color: @mainred;
    }
  }



  .logoContentWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .AILogo {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    p {
      margin-left: 4px;
      font-weight: @semi-bold;
    }

  }

  .menuButton {
    border:none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
  }

  .navLinksWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    /* Displayed when in desktop mode */
    .authButton {
      margin: 0 1rem;
      .button-black-square();

      p {
        font-weight: @semi-bold;
      }

      h4 {
        font-weight: @semi-bold;;
        color: white;
      }
  
    }
  }


}


.mobileDropDown.open {
  display: flex; /* When `menuOpen` is true, display as flex */
}

.mobileDropDown {
  margin-top: 80px;
  width: 100vw;
  max-height: 500px;
  display: none;
  text-align: center;
  position: fixed;
  flex-direction: column;
  background:@white;
  padding-top: 4rem;
  padding-bottom: 3rem;
  z-index: 99;
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out; /* You can adjust the duration and easing as needed */
  box-shadow: @bx2;

  .mobileNavItem {
    text-decoration: none;
  
    font-size: @font4;
    color: @black;
    font-weight: @semi-bold;
    margin: 1rem 0;

    a {
      color: @black;
    }
    a:hover {
      color: @mainred;
    }

  }

  .logOutOption {
    width: 100%;
    text-decoration: none;
    font-size: @font4;
    color: @black;
    font-weight: @semi-bold;
    margin: 0.5rem 0;
    :hover {
      cursor: pointer;
    }
  }


}


/* Final state - menu is visible 
 * Keep this here otherwise scoping it to another 
 * block will mess up the CSS selection
 */
.mobileDropDown.open {
  height: auto;
  transform: translateY(0);
}
