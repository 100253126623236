@import '../../styles/base.less';
@import '../../newStyles/layout.less';
@import '../../newStyles/components.less';
@import '../../newStyles/variables.less';

.TournamentRankingsPageHistorical{
  
  .noContainer();
  .generic-section();
  overflow-x: visible !important;

  .tournamentTitles {
    margin-top: 100px;
    margin-bottom: 4rem;

    h2 {
      color: @black;
      font-weight: @semi-bold;
      margin-bottom: 2rem;
    }
  }


  h3 {
    margin-bottom: 1rem;
  }
  
  .meta-data {
    font-size: @font2;
    margin-bottom: @margin-base;
  }
  .meta-data-title {
    margin-bottom: 0px;
  }
  .refresh-btn {
    margin-left: 1rem;
  }
}