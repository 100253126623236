@import '../../newStyles/text.less';
@import '../../newStyles/components.less';
@import '../../newStyles/index.less';



.EventsPage {
  // removes any default white space margins
  .noContainer();

  // prevents layout shifts caused by scrollbar 
  overflow-y: hidden;
  background: @white;

  
  // Container for title
  .eventsHeader {
    .generic-centered-section();
    .constrained-bounds();
    margin-top: 4rem;
    text-align: center;

    h4 {
      text-align: center;
      max-width: @max-width2;
      margin: 0 @margin-base2;
      width: 60%;

      @media screen and (max-width: @sm) {
        width: 70%;
      }
    }
  }

  .eventsContent {
    .generic-section();
    .constrained-bounds();

    .ant-tabs-ink-bar {
      display: none;
    }

    .eventsList {
      flex-direction: column;
      text-align: start;
      margin-top: 2rem;
      .eventsDescription {
        h3 {
          font-weight: @semi-bold;
        }
      }
    }
  }

  .eventsFAQ {
    .generic-section();
    h3 {
      font-weight: bold;
      line-height: 3rem;
    }
  
    .splitInfoRow {
      display: flex;
      align-items: center;
      margin-bottom: 4rem;
      max-width: 80%;
      .constrained-bounds();

      .infoText {
        flex: 1 1 50%;
        max-width: 80%;
        padding: @padding-base2;
  
        .infoButton {
          margin-top: @margin-base;
          margin-bottom: @margin-base;
          .button-colorful();
        }
      }
  
    }
  }
}