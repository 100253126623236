@import '../../../styles/base.less';

.Energium2020Page {
  .noContainer();
  .main-section {
    padding-top: 1rem !important;
    .tourney-btn {
      margin-right: 1rem;
      font-size: @font3;
      height: @font3 * 2;
    }
    .collectors-div {
      margin: auto;
      text-align: center;
      width: 100%;
      img {
        width: 240px;
      }
      caption {
        width: 100%;
        text-align: center;
        display: block;
      }
      margin-bottom: 1rem;
    }
  }
  overflow: hidden;
  @import '../../../styles/impactful.less';
  .seeker {
    color: @blue
  }
  .hider {
    color: @p1 !important;
  }
  .gif-div {
    
    margin: auto;
    width: 100%;
    text-align: center;
    display: inline-block;
    img {
      width: 420px;
      max-width: calc(100% - 2rem);
    }
    .caption {
      width: 100%;
      margin-top: 1rem;
      text-align: center;
    }
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
