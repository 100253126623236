@import '../../styles/base.less';
@import '../../newStyles/variables.less';
@import '../../newStyles/components.less';


.EventCard {
  background: #fafafa;
  box-shadow: @bx3;
  border-radius: @radius-base4;
  margin: 2.5rem 0;
  box-sizing: border-box;
  padding: 2rem;


  .eventHeaderTexts {

    h3 {
      font-weight: @semi-bold;
      max-width: @max-width1;
      line-height: @font4;
      color: @text-gray2;
    }

    p {
      color: @text-gray;
      font-weight: @regular;
    }
  }

  .eventInfoRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;

    .eventDateContainer {
      display: flex;
      justify-content: flex-start;
      margin-right: 1rem;

      h4 {
        color: @hotred;
        font-weight: @semi-bold;
      }

      p {
        color: @text-gray3;
      }
    }

    #eventCardButton {
      .button-black-square();
      margin-top: 1rem;
    }
  }

}

.eventModal {
  padding: 1rem;

  #eventScheduleButton {
    .button-black-square();
  }

  .eventModalContent {
    margin-top: 2rem;

    .eventModalImage {
      @media screen and (max-width: @sm) {
        width: 100%;
      }
    }

    .eventDetailSection {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: flex-start;

      h4 {
        color: @hotred;
        font-weight: @semi-bold;
      }

      p {
        color: @text-gray3;
      }

    }

    .eventDescriptionBox {
      background: @light-gray;
      padding: @padding-base2;
      border-radius: @radius-base3;
      margin-bottom: 1.5rem;
    }
  }
}

.eventIconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: @radius-rounded;

}

#calendar {
  background: #FFD4D4;
}