@import '../../../styles/base.less';

.HideAndSeek2020Page {
  .noContainer();
  overflow: hidden;
  @import '../../../styles/impactful.less';
  .seeker {
    color: @blue
  }
  .hider {
    color: @p1 !important;
  }
  .gif-div {
    
    margin: auto;
    width: 100%;
    text-align: center;
    display: inline-block;
    img {
      width: 420px;
      max-width: calc(100% - 2rem);
    }
    .caption {
      width: 100%;
      margin-top: 1rem;
      text-align: center;
    }
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
}
