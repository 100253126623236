@import '../../newStyles/text.less';
@import '../../newStyles/components.less';
@import '../../newStyles/index.less';

.ProjectsPage {
    .noContainer();

    .projectsHeader {
        .generic-centered-section();
        .constrained-bounds();
        text-align: center;
        margin-top: 4rem;

        h4 {
            max-width: @max-width2;
            margin: 0 @margin-base2;
            width: 60%;
      
            @media screen and (max-width: @sm) {
              width: 70%;
            }
      
            text-align: center;
          }
        .sortDropDown {
            margin-top: 2rem;
        }
    }

    .projectsSection {
        .constrained-bounds();
        .generic-section();
        margin-bottom: 10rem;

        .ant-tag-checkable {
            line-height: 2rem;
        }
    }

    .project-image {
        object-fit: cover;
        border-radius: @radius-base3;
        box-shadow: @bx2;
    }

    .project-info {
        width: 500px;
    }
}