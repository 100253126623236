@import '../../newStyles/variables.less';
@import '../../newStyles/text.less';
@import '../../newStyles/components.less';
@import '../../newStyles/index.less';

.About {
  .noContainer();

  background: #FAFAFA;
  
  .aboutHeader {
    .generic-centered-section();
    .constrained-bounds();
    margin-top: 4rem;
    text-align: center;

    h4 {
      max-width: @max-width3;
      line-height: 2rem;
      margin: 0 @margin-base2;
    }
  }


  .gallery {
    margin-bottom: 10rem;

    .banner {
      margin-top: 4rem;
      text-align: center;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: @orange;
      padding: 3rem 2rem;

      .iconWrapper {
        background: white;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: @radius-base3;
      }

      .statement {
        font-weight: @semi-bold;
        max-width: 400px;
        text-align: center;
        color: white;
        margin: 1rem 24px;
      }

    }

    .teamSection {
      .generic-section();
      .constrained-bounds();
    }
  }
}



.aboutImage {
  max-height: 350px;
  height: 350px;
  width: 100%;


  @media only screen and (max-width: @lg) {
    height: 280px;
  }

  @media only screen and (max-width: @md) {
    height: 280px;
  }

  @media only screen and (max-width: @sm) {
    height: 260px;
  }

  @media only screen and (max-width: @xs) {
    height: 300px;
  }

  object-fit: cover;
}


.AboutCard {
  text-align: left;
  width: 100%;

  .cardPreviewContent {
    justify-content: space-between;
    align-items: center;

    .nameTitle {
      h4 {
        font-weight: @semi-bold;

      }

      p {
        color: @text-gray;
      }
    }

  }


}


.drawerButton {
  background: @black;
  border: none;
  border-radius: @radius-base3;
  box-shadow: @bx3;
  color: white;
  font-weight: @semi-bold;

}


.drawerContent {

  .drawerHeader {
    align-items: center;
    justify-content: start;

    @media only screen and (max-width: @xs) {
      justify-content: start;
    }

    @media only screen and (max-width: @xxs) {
      justify-content: center;
    }

    .titleBox {
      margin-top: 1rem;
      width: 70%;

      @media only screen and (max-width: @xs) {
        text-align: start;
      }

      @media only screen and (max-width: @xxs) {
        width: 100%;
        text-align: center;
      }

      h4 {
        margin-top: 1rem;
        font-weight: @semi-bold;
      }

      p {
        color: @text-gray;
      }
    }
  }

  .bio {
    max-width: @sm;
    background: @light-gray;
    padding: 2rem 0;
    border-radius: @radius-base4;

    @media only screen and (max-width: @xs) {
      width: 100%;
    }

    p {
      width: 90%;
      margin: auto;

    }
  }



  .contactsBox {
    margin-top: 3rem;
    margin-bottom: 7rem;
    flex-direction: row;

    .links {

      section {
        margin-right: 1rem;
        margin-top: 1rem;
        display: flex;
        flex-direction: row;
        background: @light-gray;
        border-radius: @radius-base3;
        align-items: center;
        height: 50px;
        width: 150px;

        div {
          display: flex;
          margin-left: 8px;
          justify-content: center;
          align-items: center;
          border-radius: @radius-base2;
          background: white;
          height: 35px;
          width: 35px;
        }

        a {
          color: @text-gray;
          padding-left: 0.5rem;

          :hover {
            color: @mainred;
          }
        }
      }
    }
  }
}