@import '../../../newStyles/text.less';
@import '../../../newStyles/components.less';
@import '../../../newStyles/index.less';
@import '../../../newStyles/variables.less';

.CompetitionLandingPage {
  .noContainer();

  .competitionHeader {
    .generic-centered-section();
    .constrained-bounds();
    margin-top: 4rem;
    text-align: center;

    h1 {
      font-size: @font8;
    }

    h4 {
      text-align: center;
      max-width: @max-width2;
      margin: 0 @margin-base2;
      width: 60%;

      @media screen and (max-width: @sm) {
        width: 70%;
      }
    }

    .competitionButtons {
      .generic-section();
    }
  }

  .competitionMarkdown {
    .generic-section();
    .constrained-bounds();

    h1 {
      font-size: @font5;
      margin-top: 2rem;
      line-height: 4rem;
    }

    h2 {
      font-size: @font4;
      margin-top: 4rem;
      line-height: 2rem;
    }
    
    img {
      max-width: 100%; 
      height: auto; 
      display: block;  
      margin: 1rem auto;
    }
  
    iframe {
      max-width: 100%;
      width: 100%;
      height: auto;
      display: block;
      margin: 1rem auto;
    }

    p {
      margin-top: 2rem;
      line-height: 2rem;
    }
    
  }


}