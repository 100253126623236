@import '../../../../styles/base.less';
.CompetitionUploadPage {
  .upload-form-card {
    max-width: 80rem;
    .smack-center;
  }
  .upload-btn {
    width: 100%;
    
  }
  .input-wrapper {
    
    width: 100%;
    .ant-upload {
      width: 100%;
    }
    margin-bottom: 2rem;
  }
  .desc {
    margin-bottom: 1rem;
  }
  .tags-list {
    margin-top: 15px;
  }
}
