@import '../../../../styles/base.less';

.SubmissionDetailsPage {
  @import '../../../../styles/impactful.less';

  .meta-data {
    font-size: @font2;
    margin-bottom: @margin-base;
  }
  .meta-data-title {
    margin-bottom: 0px;
  }
  .refresh-btn {
    margin-left: 1rem;
  }
  p {
    font-size: @font2;
  }
}
