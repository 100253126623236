@import '../../newStyles/text.less';
@import '../../newStyles/components.less';
@import '../../newStyles/index.less';

.mainFooter {
    
    /* Very Important for Style Efficiency Of Website to make it Happy! */
    box-sizing: border-box;
    
    padding-left: @padding-base2 !important;
    padding-right: @padding-base2 !important;

    @media screen and (max-width: @xs) {
      padding-left: @padding-base !important;
      padding-right: @padding-base !important;
    }
    width: 100%;
    min-width: 180px;
    background-color: @black;
    color: @white;

    // Splits paragraph content and other resource content into
    // two sections along the same row
    .splitRow {
      padding: @padding-base;
      margin-top: @margin-base;
      margin-bottom: 4rem;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      // paragraph text on left side of split row
      .infoText {
        width: 50%;

        @media screen and (max-width: @xs) {
          width: 100%;
        }
      }

      // container to hold social media icons
      .socialMedia {
        margin-top: 8px;
        width: 42%;
        min-width: 180px;
        height: 100%;
        a {
          color: white;
        }
      }

      // container to hold links for board apps
      .boardPositions {
        margin-top: @margin-base;
        width: 42%;
        min-width: 190px;
        height: 100%;
        justify-content: space-between;

        .position {
          margin-top: 8px;
          margin-bottom: 8px;
          align-items: center;
          width: 100%;
          flex-wrap: wrap;

          .linkBox {
            //background: rgb(48, 48, 48);
            margin-left: 8px;

            .linkIcon {
              transform: translateY(2px);
            }
            :hover {
              cursor: pointer;
            }
          }
        }

      }
    }

    
    h4 a, p, h2, h3 {
      color: @white;
    }

    h3 {
      @color: @white;
      text-align: center;
    }

  
  }
