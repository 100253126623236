@import '../../../newStyles/text.less';
@import '../../../newStyles/components.less';
@import '../../../newStyles/variables.less';


.LoginPage {

  .noContainer();
  display: flex;
  justify-content: center;
  align-items: center;

  .loginDetails {
    .generic-centered-section();
    .constrained-bounds();

    .loginHeader {
      margin-top: 4rem;
    }
    
    p {
      text-align: center;
    }


    Form {
      width: 60%;
      max-width: 480px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      @media only screen and (max-width: @xxs) {
        width: 90%;
      }

      .loginHeader {
        margin-bottom: 2rem;
      }

  
    
      Input {
        line-height: 2.5rem ;
        font-size: @font3;
      }

      .danger {
        color: @danger;
        text-align: left;
        margin-top: -12px;
        margin-bottom: 24px;
      }

      #loginButton {
        width: 100%;
        height:45px;
        .button-black-square();
        margin: auto;

      }

      .authOptionsBox {
        margin-top: 24px;
        .option {
          margin-bottom: 8px;
        }
      }

    }
  }
}

.ant-input {
  background-color: @white
}
/*
.LoginPage {

  .danger {
    color: @danger;
  }
  .loginButton {
    margin-top: @margin-base;
  }
  .loginCard {
    margin-top: 9.5rem;
    .smack-center();
    max-width: 90%;
  }
  .register-info {
    margin-top: 12px;
  }
}
*/
